import React from "react";
import { useNavigate } from "react-router-dom";
import { 
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
} from "@mui/material";
import outdoor from "./../images/outdoor.png";
import cinema from "./../images/cinema.png";
import digital from "./../images/digitalAdd.png";
import event from "./../images/event.png";
import newspaper from "./../images/newspaper.png";

const advertisements = [
  {
    path: digital,
    label: "Digital Advertising",
    navigateTo: "/affilate/digitaladd",
  },
  { path: event, label: "Event Advertising", navigateTo: "/affilate/digitaladd" },
  {
    path: newspaper,
    label: "Newspaper Advertising",
    navigateTo: "/affilate/digitaladd",
  },
  {
    path: outdoor,
    label: "Outdoor Advertising",
    navigateTo: "/affilate/digitaladd",
  },
  {
    path: cinema,
    label: "Cinema Advertising",
    navigateTo: "/affilate/digitaladd",
  },
];

function AddPushNotification() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        background: "linear-gradient( #A5BFD9, #A7D3FF)",
        p: 4,
        height: { xs: "auto", md: "100vh" },
      }}
    >
      <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
        ADD SPACE
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(240px, 1fr))",
          gap: 3,
        }}
      >
        {advertisements.map((ad, index) => (
          <Card
            elevation={18}
            sx={{ maxWidth: 345, maxHeight: 599 }}
            key={index}
            onClick={() => navigate(ad.navigateTo)}
          >
            <CardActionArea>
              <CardMedia
                component="img"
                height="150"
                image={ad.path}
                alt={ad.label}
              />
              <Typography
                gutterBottom
                variant="h6"
                component="div"
                mt={1}
                sx={{ textAlign: "center" }}
              >
                {ad.label}
              </Typography>
            </CardActionArea>
          </Card>
        ))}
      </Box>
    </Box>
  );
}

export default AddPushNotification;
