import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Select,
  MenuItem,
  Grid,
  Typography,
  Button,
  FormControl,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { getUserFromLocalStorage } from "src/service/localStorage";
import { toast } from "react-toastify";
import axios from "axios";
import { Modal } from "react-bootstrap";
import QrPayment from "../images/laxmikant_fedral.jpeg";
import account from "src/_mock/account";
import WalletBankStatementInfoCard from "src/components/wallet/WalletBankStatementInfoCard";
import walletMoneyAddedImg from "../images/wallet-money-added.svg";
import PaidForCampBillImg from "../images/Order.svg";

function Wallet() {
  const theme = useTheme();
  const [selectedValue, setSelectedValue] = useState("Option1");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [amount, setAmount] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [totalRemainingBalance, setTotalRemainingBalance] = useState(0);
  const [addedWalletStatement, setAddedWalletStatement] = useState([]);
  const [campBillStateMent, setCampBillStateMent] = useState([]);
  const combinedData = [...addedWalletStatement, ...campBillStateMent];

  const user = getUserFromLocalStorage();
  const accessToken = user?.data?.access_token;
  const URL = process.env.REACT_APP_PROD_API;
  const affiliate_id = account.affiliate_id;
  const URL2 = process.env.REACT_APP_PROD_FILINGSOLUTIONS_API;

  const handleShow1 = () => setShow1(true);
  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
    setPage(0);
  };

  const addbalanceToWallet = async () => {
    try {
      const url = `${URL}/api/wallet/add-balance`;
      const data = {
        amount: amount,
        order_id: orderId,
      };

      const res = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log("Add Balance -->", res?.data);

      if (res.status === 200) {
        toast.success(res?.data?.message);
        setAmount(0);
        setOrderId("");
        handleClose1();
      }
    } catch (error) {
      console.log("Error While Adding Balance -->", error);
      toast.error("Error While Adding Balance !!");
    }
  };

  const getTotalRemainingBalance = async () => {
    try {
      const url = `${URL}/api/wallet/total-remaining-balance`;
      // console.log("Access token ", accessToken);

      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // console.log("Total Remaining Balance -->", res?.data);
      setTotalRemainingBalance(
        Number(res?.data?.total_remaining_balance?.toFixed(2))
      );
    } catch (error) {
      console.log("Error While Getting Total Remaining Balance -->", error);
      toast.error("Error While Getting Total Remaining Balance !!");
    }
  };

  const getladdedWalletStatement = async () => {
    try {
      const url = `${URL}/api/wallet/balance-statements`;
      // console.log("Access token ", accessToken);

      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      // console.log("Balance Statements -->", res?.data);
      setAddedWalletStatement(res?.data);
    } catch (error) {
      console.log("Error While Statements  -->", error);
      toast.error("Error While Statements !!");
    }
  };

  const getJobData = async () => {
    try {
      // const url = https://auto-api-affworld.vercel.app/api/particularjobs/${affiliate_id};
      const url = `${URL2}/api/particularjobs/${affiliate_id}`;

      const res = await axios.get(url);
      setCampBillStateMent(res.data);
      // console.log(" this is jobs  data--->", res?.data);
    } catch (error) {
      console.log("Erro In getting jobs data--->", error);
      toast.error("Error In getting jobs data!!");
    }
  };

  const formatLocalDate = (utcTimestamp) => {
    const utcDate = new Date(utcTimestamp);
    // Add 5 hours and 30 minutes to convert to IST
    utcDate.setHours(utcDate.getHours() + 5);
    utcDate.setMinutes(utcDate.getMinutes() + 30);

    // Format the date to a string in a way that suits your needs
    const istTimeString = utcDate.toLocaleString("en-In", {
      timeZone: "Asia/Kolkata", // Set the timezone to IST
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return istTimeString;
  };

  ///
  const sortedArray = combinedData.sort(
    (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
  );

  console.log(sortedArray);
  useEffect(() => {
    getJobData();

    getTotalRemainingBalance();
    getladdedWalletStatement();
  }, []);
  return (
    <div>
      <Grid container>
        <Grid item md={7} xs={4}></Grid>
        <Grid item md={5} xs={8}>
          <Grid container spacing={4} bgcolor={""}>
            <Grid
              item
              xs={6}
              md={7}
              className="d-flex justify-content-center align-items-center "
            >
              <Button
                className="w-100 bg-info text-dark rounded-1"
                type="button"
                onClick={handleShow1}
              >
                {" "}
                Add Money
              </Button>
            </Grid>
            <Grid item xs={6} md={5} className="">
              <Box className=" ">
                <Typography variant="body2">Total Balance</Typography>
                <Typography variant="subtitle1">
                  ₹ {totalRemainingBalance}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box component={"div"} my={4} borderTop={"1px solid gray"}></Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="" sx={{ width: "400px" }}>
                <Select
                  value={selectedValue}
                  onChange={handleDropdownChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Header 1" }}
                  className=""
                  sx={{
                    height: "40px",
                    width: "150px",
                    border: "none",
                    "&:focus": {
                      outline: "none",
                    },
                  }}
                >
                  <MenuItem value="Option1">All Trasaction</MenuItem>
                  <MenuItem value="Option2">Added Balance</MenuItem>
                  <MenuItem value="Option3">Total Bill</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </TableCell>
              <TableCell className="text-center w-25" style={{ flex: 2 }}>
                Amount
              </TableCell>
              <TableCell className="text-center w-25" style={{ flex: 2 }}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          {selectedValue === "Option1" && (
            <TableBody>
              {combinedData
                .map((item) => ({
                  ...item,
                  timestamp:
                    item.timestamp ||
                    (item.apiResponse && item.apiResponse[0]?.timestamp),
                }))
                .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  const {
                    name,
                    amount,
                    order_id,
                    timestamp,
                    apiResponse = [],
                    totalCharges,
                  } = row;
                  const apiOrder = apiResponse[0]?.response?.order || "";

                  return (
                    <TableRow key={i}>
                      <TableCell className="text-center" style={{ flex: 1 }}>
                        <WalletBankStatementInfoCard
                          WalletTitle={
                            totalCharges
                              ? name
                                ? `Paid for ${name} Campaign Order`
                                : "Paid for Campaign Order"
                              : "Added to Wallet"
                          }
                          WalletDate={
                            !apiResponse.length
                              ? formatLocalDate(timestamp)
                              : formatLocalDate(apiResponse[0].timestamp)
                          }
                          WalletOrderId={`Order id : ${order_id || apiOrder}`}
                          WalletImg={
                            totalCharges
                              ? PaidForCampBillImg
                              : walletMoneyAddedImg
                          }
                        />
                      </TableCell>
                      <TableCell className="text-center" style={{ flex: 1 }}>
                        {amount
                          ? `+ ${amount}`
                          : `- ${totalCharges.toFixed(2)}`}
                      </TableCell>
                      <TableCell align="center">Success</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          )}

          {selectedValue === "Option2" && (
            <TableBody>
              {addedWalletStatement
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  const {
                    name,
                    amount,
                    order_id,
                    timestamp,
                    apiResponse = [],
                    totalCharges,
                  } = row;
                  const apiOrder = apiResponse[0]?.response?.order || "";

                  return (
                    <TableRow key={i}>
                      <TableCell className="text-center" style={{ flex: 1 }}>
                        <WalletBankStatementInfoCard
                          WalletTitle={
                            totalCharges
                              ? name
                                ? `Paid for ${name} Campaign Order`
                                : "Paid for Campaign Order"
                              : "Added to Wallet"
                          }
                          WalletDate={
                            !apiResponse.length
                              ? formatLocalDate(timestamp)
                              : formatLocalDate(apiResponse[0].timestamp)
                          }
                          WalletOrderId={`Order id : ${order_id || apiOrder}`}
                          WalletImg={
                            totalCharges
                              ? PaidForCampBillImg
                              : walletMoneyAddedImg
                          }
                        />
                      </TableCell>
                      <TableCell className="text-center" style={{ flex: 1 }}>
                        {amount
                          ? `+ ${amount}`
                          : `- ${totalCharges.toFixed(2)}`}
                      </TableCell>
                      <TableCell align="center">Success</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          )}

          {selectedValue === "Option3" && (
            <TableBody>
              {campBillStateMent
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  const {
                    name,
                    amount,
                    order_id,
                    timestamp,
                    apiResponse = [],
                    totalCharges,
                  } = row;
                  const apiOrder = apiResponse[0]?.response?.order || "";

                  return (
                    <TableRow key={i}>
                      <TableCell className="text-center" style={{ flex: 1 }}>
                        <WalletBankStatementInfoCard
                          WalletTitle={
                            totalCharges
                              ? name
                                ? `Paid for ${name} Campaign Order`
                                : "Paid for Campaign Order"
                              : "Added to Wallet"
                          }
                          WalletDate={
                            !apiResponse.length
                              ? formatLocalDate(timestamp)
                              : formatLocalDate(apiResponse[0].timestamp)
                          }
                          WalletOrderId={`Order id : ${order_id || apiOrder}`}
                          WalletImg={
                            totalCharges
                              ? PaidForCampBillImg
                              : walletMoneyAddedImg
                          }
                        />
                      </TableCell>
                      <TableCell className="text-center" style={{ flex: 1 }}>
                        {amount
                          ? `+ ${amount}`
                          : `- ${totalCharges.toFixed(2)}`}
                      </TableCell>
                      <TableCell align="center">Success</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={
          selectedValue === "Option1"
            ? combinedData.length
            : selectedValue === "Option2"
            ? addedWalletStatement.length
            : selectedValue === "Option3"
            ? campBillStateMent.length
            : 0
        }
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Modal
        show={show1}
        onHide={handleClose1}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Box
          sx={{
            position: "absolute",
            top: "25px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "white",
            boxShadow: 24,
            p: 0,
            width: "90%",
            borderRadius: "8px",

            [theme.breakpoints.up("md")]: {
              top: "25px",
              borderRadius: "8px",
              width: "60%",
            },
          }}
        >
          <Modal.Body>
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  className="mt-3"
                  src={QrPayment}
                  alt="QR Code"
                  style={{ width: "40%" }}
                />
              </div>
              <div style={{ textAlign: "center", marginBottom: "20px" }}>
                <h3
                  style={{
                    fontSize: "1.5em",
                    fontWeight: "bold",
                    color: "#333",
                  }}
                >
                  Minimum Amount: Rs.50
                </h3>
                <p style={{ fontSize: "1em", color: "#6E7A83" }}>
                  Once you've made the payment, please enter the UTR number and
                  amount below. Submit the details, and your balance will be
                  added within 5-10 minutes.
                </p>
              </div>

              <form>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                  }}
                >
                  <FormControl fullWidth sx={{ marginBottom: 2, width: "50%" }}>
                    <TextField
                      variant="outlined"
                      value={amount}
                      type="number"
                      placeholder="Enter Amount"
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ marginBottom: 2, width: "50%" }}>
                    <TextField
                      id="outlined-basic"
                      label="Enter UTR"
                      variant="outlined"
                      value={orderId}
                      onChange={(e) => setOrderId(e.target.value)}
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={addbalanceToWallet}
                    sx={{
                      width: "50%",
                      borderRadius: "30px",
                    }}
                  >
                    Send Deatils
                  </Button>
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="contained" color="error" onClick={handleClose1}>
              Close
            </Button>
          </Modal.Footer>
        </Box>
      </Modal>
    </div>
  );
}

export default Wallet;
