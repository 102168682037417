import React, { useEffect, useState } from "react";
import { alpha } from "@mui/material/styles";
import {
  Divider,
  Stack,
  Avatar,
  IconButton,
  Popover,
  useTheme,
  Typography,
  Button,
  Fade,
  Modal,
  Box,
  Backdrop,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LogoutIcon from "@mui/icons-material/Logout";
import account from "../../../_mock/account";
import {
  getResFromLocalStorage,
  removeUserFromLocalStorage,
  getUserFromLocalStorage,
} from "../../../service/localStorage";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxWidth: 600,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  { label: "Home", icon: "eva:home-fill" },
  { label: "Settings", icon: "eva:settings-2-fill" },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const user1 = getResFromLocalStorage();
  const user = getUserFromLocalStorage();
  const accessToken = user?.data?.access_token;
  const URL2 = process.env.REACT_APP_PROD_API;
  const url1 = `${URL2}/api/affiliates`;
  const [affiliateData, setAffiliateData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleOpen = (event) => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleProfile = () => {
    console.log("Profile clicked");
  };

  const handleClick = () => {
    console.log("Finance clicked");
    navigate("/affilate/finance");
    handleClose();
  };

  const handleClickEdit = () => {
    console.log("Edit clicked");
    navigate("/affilate/user/details");
    handleClose();
  };

  const handleFinance = () => {
    console.log("Payment clicked");
    navigate("/affilate/payment/details");
    handleClose();
  };

  const handleDynamicAds = () => {
    console.log("DynamicAds clicked");
    navigate("/affilate/dynamicAds");
    handleClose();
  };

  const handleLogout = () => {
    console.log("Logout clicked");
    removeUserFromLocalStorage();
    toast.success("Logout successfully", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    setTimeout(() => {
      navigate("/login");
    }, 1000);
  };

  const fetchAffiliateData = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };
      const response = await fetch(url1, { method: "GET", headers });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const jsonData = await response.json();
      setAffiliateData(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        await fetchAffiliateData();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    init();
  }, []);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div style={{ textAlign: "center", position: "relative" }}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: { xs: 20, md: 28 },
                  fontWeight: 600,
                  fontFamily: "Poppins",
                  textAlign: "center",
                }}
              >
                Hi! {account?.displayName}
              </Typography>
              <Avatar
                alt={account?.displayName}
                src={account?.photoURL}
                sx={{
                  borderRadius: "50%",
                  margin: "auto",
                  width: 150,
                  height: 150,
                  mt: 2,
                  textAlign: "center",
                }}
              />
              <Typography
                variant="subtitle1"
                sx={{
                  backgroundColor: "gray.100",
                  fontSize: 18,
                  marginY: 1,
                  fontFamily: "Poppins",
                  textAlign: "center",
                }}
              >
                Email: {account?.email}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  backgroundColor: "gray.100",
                  fontSize: 18,
                  marginY: 1,
                  fontFamily: "Poppins",
                  textAlign: "center",
                }}
              >
                Bio: {account?.bio}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  backgroundColor: "gray.100",
                  fontSize: 18,
                  marginY: 1,
                  fontFamily: "Poppins",
                  textAlign: "center",
                }}
              >
                Level: {account?.level}
              </Typography>

              <Stack
                direction="row"
                spacing={{ xs: 1, sm: 2, md: 2 }}
                justifyContent="center"
                mt={2}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    flex: 1,
                    margin: { xs: 1, sm: 1, md: 2 },
                  }}
                  onClick={handleFinance}
                >
                  Payment Details
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    flex: 1,
                    margin: { xs: 1, sm: 1, md: 2 },
                  }}
                  onClick={handleClickEdit}
                >
                  Overview
                </Button>
              </Stack>

              <Stack
                direction="row"
                spacing={{ xs: 1, sm: 2, md: 2 }}
                justifyContent="center"
                mt={2}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    flex: 1,
                    margin: { xs: 1, sm: 1, md: 2 },
                  }}
                  onClick={handleClick}
                >
                  Finance
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    flex: 1,
                    margin: { xs: 1, sm: 1, md: 2 },
                  }}
                  onClick={handleDynamicAds}
                >
                  Dynamic Ads
                </Button>
              </Stack>
              <LogoutIcon
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                  cursor: "pointer",
                }}
                onClick={handleLogout}
              />
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
